import '../assets/images/logo.png';
import '../assets/images/ansys.png';
import '../assets/images/logo-ansys.png';
import '../assets/images/check.png';
import '../assets/images/certified.png';
import '../assets/images/contacts-map.png';
import '../assets/images/post-default-img.png';
import '../assets/images/banner-default-img.png';
import '../assets/images/pin.png';
import '../assets/images/pattern.png';
import '../assets/images/check-mark.png';
