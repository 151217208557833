export default {
  navSlideSpeed: 200,
  outClickBlocks: [],
  windowOnload: [],
  windowOnscroll: [],
  window: {
    onLoad: [],
    onScroll: [],
    onResize: [],
  },
  document: {
    onClick: [],
  },
};
