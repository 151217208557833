// CADFEM-MODULES
import './cadfem';
import './bootstrap';

// ///////////////
import APP from '../APP';

const labelArrFilter = document.querySelectorAll('[data-component="scheduleFilter"]');
const labelArrFilter2 = document.querySelectorAll('[data-component="scheduleFilter2"]');

if (labelArrFilter.length && labelArrFilter2.length) {
  APP.mediator = {};
  import(/* webpackChunkName: "scheduleFilter2" */ './local/scheduleFilter2')
    .then((module) => {
      APP.mediator.day = new module.default();
      APP.mediator.updateFrames = () => APP.mediator.day.updateFrames();
      APP.mediator.checkDays = () => APP.mediator.day.isAllUnchecked();
      APP.mediator.checkDaysSessions = () => APP.mediator.day.checkSessions();
    });
  import(/* webpackChunkName: "scheduleFilter" */ './local/scheduleFilter')
    .then((module) => {
      APP.mediator.filter = new module.default();
    });
}
