// lazy
import './lazy/img';
import './lazy/bg';
import './lazy/pswpGallery';

// import modules
import './modules/_index';

// bind document/window events
// import './DOMEvents/_index';

// images
import './images';

// require all svg in svg folder
import('./additional/svgRequire');

const controlAll = document.querySelectorAll('[data-control]');
const controlArr = Array.prototype.slice.call(controlAll);

if (controlArr.length > 0) {
  const getActiveEvent = () => controlArr.filter((item) => item.type === 'radio' && item.checked)[0].value;
  let ActiveEvent = getActiveEvent();
  let ActiveEventList = document.querySelector(`[data-event="${ActiveEvent}"]`);
  let EmptyBlock = ActiveEventList.querySelector('[data-empty]');

  controlArr.forEach((item) => {
    const control = item.getAttribute('data-control');

    item.addEventListener('change', (e) => {
      const itemValue = e.target.value;
      const itemType = e.target.type;

      const sessionListAll = document.querySelectorAll(`[data-${control}]`);
      const sessionListArr = Array.prototype.slice.call(sessionListAll);

      if (itemType === 'radio') {
        sessionListArr.forEach((session) => {
          const sessionListAttr = session.getAttribute(`data-${control}`);
          // eslint-disable-next-line no-param-reassign
          session.style.display = sessionListAttr === itemValue ? 'block' : 'none';
        });
        ActiveEvent = getActiveEvent();
        ActiveEventList = document.querySelector(`[data-event="${ActiveEvent}"]`);
        EmptyBlock = ActiveEventList.querySelector('[data-empty]');
      } else if (itemType === 'checkbox') {
        const values = new Map();

        controlArr
          .filter((ctrl) => ctrl.type === 'checkbox')
          .forEach((controlEl) => (controlEl.checked ? values.set(controlEl.value, '') : false));

        if (values.size > 0) {
          const visibleSessions = [];
          sessionListArr.forEach((session) => {
            const sessionListAttr = session.getAttribute(`data-${control}`);
            const exist = sessionListAttr.split(',').some((sess) => values.has(sess));

            // eslint-disable-next-line no-param-reassign
            session.style.display = exist ? 'block' : 'none';

            if (exist && ActiveEventList.contains(session)) visibleSessions.push(session);
          });

          EmptyBlock.style.display = visibleSessions.length ? 'none' : 'block';
        } else {
          // eslint-disable-next-line no-param-reassign,no-return-assign
          sessionListArr.forEach((sess) => sess.style.display = 'block');
          EmptyBlock.style.display = 'none';
        }
      }
    });
  });
}

if (document.querySelectorAll('timer-el').length !== 0) import('./Timer');
